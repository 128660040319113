import React, { Component } from "react";
import { connect } from "react-redux";
import clone from "clone";
import { Link, NavLink } from "react-router-dom";
import { Icon, Layout } from "antd";
import Scrollbars from "components/utility/customScrollBar.js";
import Menu from "components/uielements/menu";
import isHandleSettingProduct from "../../helpers/isHandleSettingProduct";
import SidebarWrapper from "./sidebar.style";
import appActions from "redux/app/actions";
import Logo from "components/utility/logo";
import isInApp from "../../helpers/isInApp";
import hasPermission from "../../helpers/hasPermission";
import translateMsg from "../../helpers/translateMsg";
import { withTranslation } from "react-i18next";
import { client } from "settings";
import isMobile from "helpers/isMobile";
import getMenuItemName from "helpers/getMenuItemName";

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

const { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed } =
  appActions;
const stripTrailingSlash = (str) => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
    this.state = {
      reload: false,
    };
  }
  handleClick(e) {
    this.props.changeCurrent([e.key]);

    if (this.props.app.view === "MobileView") {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }
  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      (key) => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      (key) => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = (key) => {
    const map = {
      sub3: ["sub2"],
    };
    return map[key] || [];
  };

  _changeBusiness = () => {
    const { user } = this.props;

    if (user && user.businessesUser && user.businessesUser.length > 1) {
      return (
        <div onClick={this.handleClick}>
          <Icon
            style={{ marginLeft: 20, fontSize: 18 }}
            type="select"
            onClick={this._executeChangeBusiness}
          />
        </div>
      );
    }
    return false;
  };

  _executeChangeBusiness = () => {
    //localStorage.removeItem("business_uuid");
    //document.location.href = "/dashboard";

    this.props.history.push("/dashboard/setting");

    //document.location.href = "/dashboard/setting";
  };

  getBusinessName = () => {
    const { businessInfo } = this.props;

    if (businessInfo.name) {
      return businessInfo.name;
    }

    return false;
  };

  getBusinessWhiteLabelName = () => {
    const { businessInfo } = this.props;

    if (businessInfo?.whitelabel?.name) {
      return businessInfo?.whitelabel?.name;
    }

    return false;
  };

  render() {
    const {
      app,
      toggleOpenDrawer,
      customizedTheme,
      height,
      businessInfo,
      user,
      settingProduct,
    } = this.props;

    window.onresize = () => {
      this.setState({ reload: !this.state.reload });
    };

    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    const mode = collapsed === true ? "vertical" : "inline";
    const onMouseEnter = (event) => {
      if (openDrawer === false) {
        toggleOpenDrawer();
      }
      return;
    };
    const onMouseLeave = () => {
      if (openDrawer === true) {
        toggleOpenDrawer();
      }
      return;
    };
    const styling = {
      backgroundColor: customizedTheme.backgroundColor,
    };
    const submenuStyle = {
      //backgroundColor: "rgba(0,0,0,0.3)",
      //color: customizedTheme.textColor,
    };
    const submenuColor = {
      //color: customizedTheme.textColor,
    };

    //return false;

    //const parsed = queryString.parse(document.location.search);
    //console.log(111111);
    //console.log(parsed);
    //if (parsed.app) {
    if (isInApp() === true) {
      return false;
    }

    const handleProductDiscount = businessInfo?.configurations?.find(
      (conf) => conf.name === "handle_product_discount"
    );

    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width={isMobile() ? window.innerWidth : 300}
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo collapsed={collapsed} />
          <Scrollbars style={{ height: height - 70 }}>
            {Object.keys(businessInfo).length > 0 && (
              <Menu
                onClick={this.handleClick}
                theme="dark"
                className="isoDashboardMenu"
                mode={mode}
                openKeys={collapsed ? [] : app.openKeys}
                selectedKeys={app.current}
                onOpenChange={this.onOpenChange}
              >
                <div className="businessName" style={{backgroundColor:'#dfdfdf'}}>
                 <div style={{display:'flex', flexDirection:'column', padding:10, flex:1}}>
                  <span style={{ }}>{this.getBusinessName()}</span>
             
                  <span style={{fontSize:13, flex:1, marginTop:-5}}>{this.getBusinessWhiteLabelName()}</span>
                  </div>
                  {this._changeBusiness()}
                </div>

                <SubMenu
                  key="rsub1"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-ios-information" />
                      <span className="nav-text">
                        {translateMsg(this.props.t, "back", "back_ui_infos")}
                      </span>
                    </span>
                  }
                >
                  <Menu.Item style={submenuStyle} key="r1">
                    <NavLink style={submenuColor} to="/dashboard/address">
                      {translateMsg(this.props.t, "back", "back_ui_address")}
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="r13">
                    <Link style={submenuColor} to="/dashboard/language">
                      {translateMsg(
                        this.props.t,
                        "webapp",
                        "pwa_ui__languages"
                      )}
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="r2">
                    <Link style={submenuColor} to="/dashboard/information">
                      {translateMsg(
                        this.props.t,
                        "back",
                        "back_ui_information"
                      )}
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="r3">
                    <Link style={submenuColor} to="/dashboard/communication">
                      {translateMsg(
                        this.props.t,
                        "back",
                        "back_ui_communication"
                      )}
                    </Link>
                  </Menu.Item>

                  {businessInfo.whitelabel &&
                    +businessInfo.whitelabel?.handle_payment === 1 && (
                      <Menu.Item style={submenuStyle} key="r4">
                        <Link style={submenuColor} to="/dashboard/payment">
                          {translateMsg(
                            this.props.t,
                            "webapp",
                            "pwa_ui__payment"
                          )}
                        </Link>
                      </Menu.Item>
                    )}

                  {businessInfo.whitelabel &&
                    +businessInfo.whitelabel?.handle_activity === 1 && (
                      <Menu.Item style={submenuStyle} key="r533">
                        <Link style={submenuColor} to="/dashboard/activity">
                          {translateMsg(
                            this.props.t,
                            "webapp",
                            "pwa_ui_activities"
                          )}
                        </Link>
                      </Menu.Item>
                    )}

                  {businessInfo.whitelabel &&
                    +businessInfo.whitelabel?.handle_service === 1 && (
                      <Menu.Item style={submenuStyle} key="r5">
                        <Link style={submenuColor} to="/dashboard/service">
                          {translateMsg(
                            this.props.t,
                            "webapp",
                            "pwa_ui__SERVICES"
                          )}
                        </Link>
                      </Menu.Item>
                    )}

                  {businessInfo.whitelabel &&
                    businessInfo.whitelabel?.handle_speciality_food === 1 && (
                      <Menu.Item style={submenuStyle} key="r55">
                        <Link
                          style={submenuColor}
                          to="/dashboard/specialityFood"
                        >
                          {translateMsg(
                            this.props.t,
                            "webapp",
                            "pwa_ui__culinary_specialties"
                          )}
                        </Link>
                      </Menu.Item>
                    )}

                  {isHandleSettingProduct(
                    businessInfo,
                    settingProduct,
                    "handle_regime"
                  ) === true && (
                    <Menu.Item style={submenuStyle} key="r7">
                      <Link style={submenuColor} to="/dashboard/regime">
                        {translateMsg(this.props.t, "back", "back_ui_diet")}
                      </Link>
                    </Menu.Item>
                  )}

                  {businessInfo.whitelabel &&
                    businessInfo.whitelabel?.handle_social === 1 && (
                      <Menu.Item style={submenuStyle} key="r8">
                        <Link style={submenuColor} to="/dashboard/social">
                          {translateMsg(this.props.t, "back", "back_ui_social")}
                        </Link>
                      </Menu.Item>
                    )}

                  <Menu.Item style={submenuStyle} key="rsa89">
                    <Link style={submenuColor} to="/dashboard/cookie">
                      {translateMsg(this.props.t, "back", "back_ui_cookies")}
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="rsa891">
                    <Link style={submenuColor} to="/dashboard/traduction">
                      {translateMsg(this.props.t, "back", "back_ui_traduction")}
                    </Link>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="rsub8"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-plus-circled" />
                      <span className="nav-text">
                        {translateMsg(this.props.t, "back", "back_ui_features")}
                      </span>
                    </span>
                  }
                >
                  {businessInfo.whitelabel &&
                    +businessInfo.whitelabel.handle_booking === 1 && (
                      <Menu.Item style={submenuStyle} key="rsbbb5">
                        <Link
                          style={submenuColor}
                          to="/dashboard/serviceBooking"
                        >
                          {translateMsg(
                            this.props.t,
                            "manager",
                            "mapp_ui_reservations"
                          )}
                        </Link>
                      </Menu.Item>
                    )}

                  {businessInfo.whitelabel &&
                    +businessInfo.whitelabel?.handle_booking === 1 &&
                    +businessInfo.handle_booking === 1 && (
                      <Menu.Item style={submenuStyle} key="rsa4eet">
                        <Link style={submenuColor} to="/dashboard/bookingOrder">
                          {translateMsg(
                            this.props.t,
                            "back",
                            "back_ui_status_res"
                          )}
                        </Link>
                      </Menu.Item>
                    )}

                  <Menu.Item style={submenuStyle} key="rsa3">
                    <Link style={submenuColor} to="/dashboard/serviceOrder">
                      {translateMsg(this.props.t, "manager", "mapp_ui_orders")}
                    </Link>
                  </Menu.Item>

                  {+businessInfo.handle_order === 1 && (
                    <Menu.Item style={submenuStyle} key="rsa4ee">
                      <Link style={submenuColor} to="/dashboard/statusOrder">
                        {translateMsg(
                          this.props.t,
                          "back",
                          "back_ui_status_order"
                        )}
                      </Link>
                    </Menu.Item>
                  )}

                  {+businessInfo.delivery === 1111 && (
                    <Menu.Item style={submenuStyle} key="rsa5">
                      <Link
                        style={submenuColor}
                        to="/dashboard/serviceDelivery"
                      >
                        {translateMsg(this.props.t, "back", "back_ui_delivery")}
                      </Link>
                    </Menu.Item>
                  )}

                  {businessInfo.whitelabel &&
                    +businessInfo.whitelabel.handle_register_cash === 1 && (
                      <Menu.Item style={submenuStyle} key="rsa6">
                        <Link
                          style={submenuColor}
                          to="/dashboard/cash_register_setting"
                        >
                          {translateMsg(
                            this.props.t,
                            "back",
                            "back_ui_cash_register"
                          )}
                        </Link>
                      </Menu.Item>
                    )}
                </SubMenu>

                <SubMenu
                  key="rsub2"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-android-alarm-clock" />
                      <span className="nav-text">
                        {translateMsg(
                          this.props.t,
                          "back",
                          "back_ui_hours_res"
                        )}
                      </span>
                    </span>
                  }
                >
                  <Menu.Item style={submenuStyle} key="rsbbb1">
                    <Link style={submenuColor} to="/dashboard/openingHour">
                      {translateMsg(
                        this.props.t,
                        "webapp",
                        "pwa_ui__opening_hours"
                      )}
                    </Link>
                  </Menu.Item>

                  {+businessInfo.handle_opening_hours === 1 && (
                    <Menu.Item style={submenuStyle} key="rsbbb2">
                      <Link style={submenuColor} to="/dashboard/specialDate">
                        {translateMsg(
                          this.props.t,
                          "manager",
                          "mapp_ui_exceptional_closures"
                        )}
                      </Link>
                    </Menu.Item>
                  )}

                  <Menu.Item style={submenuStyle} key="rsbbb3">
                    <Link style={submenuColor} to="/dashboard/showtime">
                      {translateMsg(this.props.t, "back", "back_ui_time_slots")}
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="rsbbb59877">
                    <Link style={submenuColor} to="/dashboard/restock">
                      {translateMsg(this.props.t, "back", "back_ui_restock")}
                    </Link>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="rsub3"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-android-locate" />
                      <span className="nav-text">
                        {translateMsg(
                          this.props.t,
                          "back",
                          "back_ui_emplacement"
                        )}
                      </span>
                    </span>
                  }
                >
                  <Menu.Item style={submenuStyle} key="rsbbb8765">
                    <Link style={submenuColor} to="/dashboard/zone">
                      {translateMsg(this.props.t, "back", "back_ui_zones")}
                    </Link>
                  </Menu.Item>

                  {businessInfo.whitelabel &&
                    +businessInfo.whitelabel.handle_table === 1 && (
                      <Menu.Item style={submenuStyle} key="rsbbb777">
                        <Link style={submenuColor} to="/dashboard/table">
                          {translateMsg(
                            this.props.t,
                            "manager",
                            "mapp_ui_tables"
                          )}
                        </Link>
                      </Menu.Item>
                    )}
                </SubMenu>

                <SubMenu
                  key="rsub4"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-ios-book" />
                      <span className="nav-text">
                        {translateMsg(this.props.t, "webapp", "pwa_ui_catalog")}
                      </span>
                    </span>
                  }
                >
                  {businessInfo.whitelabel &&
                    +businessInfo.whitelabel?.handle_price_value === 1 && (
                      <Menu.Item style={submenuStyle} key="rs10">
                        <Link style={submenuColor} to="/dashboard/pricing">
                          {translateMsg(
                            this.props.t,
                            "back",
                            "back_ui_pricing"
                          )}
                        </Link>
                      </Menu.Item>
                    )}

                  {isHandleSettingProduct(
                    businessInfo,
                    settingProduct,
                    "handle_attribute"
                  ) === true && (
                    <Menu.Item style={submenuStyle} key="rs11">
                      <Link style={submenuColor} to="/dashboard/attributes">
                        {translateMsg(
                          this.props.t,
                          "back",
                          "back_ui_attributes"
                        )}
                      </Link>
                    </Menu.Item>
                  )}

                  {+businessInfo.handle_ingredient_extra === 1 &&
                    businessInfo.whitelabel &&
                    +businessInfo.whitelabel?.handle_product === 1 && (
                      <Menu.Item style={submenuStyle} key="rs5">
                        <Link
                          style={submenuColor}
                          to="/dashboard/ingredientIncluded"
                        >
                          {translateMsg(
                            this.props.t,
                            "manager",
                            "mapp_ui_ingredientIncluded"
                          )}
                        </Link>
                      </Menu.Item>
                    )}

                  {+businessInfo.handle_ingredient_extra === 1 &&
                    businessInfo.whitelabel &&
                    businessInfo.whitelabel?.handle_product === 1 && (
                      <Menu.Item style={submenuStyle} key="rs4">
                        <Link
                          style={submenuColor}
                          to="/dashboard/ingredientAdded"
                        >
                          {translateMsg(
                            this.props.t,
                            "manager",
                            "mapp_ui_ingredientAdded"
                          )}
                        </Link>
                      </Menu.Item>
                    )}

                  <Menu.Item style={submenuStyle} key="rs1">
                    <Link style={submenuColor} to="/dashboard/statictext">
                      {translateMsg(this.props.t, "back", "back_ui_statictext")}
                    </Link>
                  </Menu.Item>

                  {+businessInfo.handle_garnish === 1 &&
                    businessInfo.whitelabel &&
                    +businessInfo.whitelabel?.handle_product === 1 && (
                      <Menu.Item style={submenuStyle} key="rs2">
                        <Link style={submenuColor} to="/dashboard/garnish">
                          {translateMsg(
                            this.props.t,
                            "back",
                            "back_ui_side_dishes"
                          )}
                        </Link>
                      </Menu.Item>
                    )}

                  <Menu.Item style={submenuStyle} key="rs3">
                    <Link style={submenuColor} to="/dashboard/category">
                      {translateMsg(
                        this.props.t,
                        "back",
                        "back_ui_category_plur"
                      )}
                    </Link>
                  </Menu.Item>

                  {typeof businessInfo.whitelabel.handle_form === "number" &&
                    businessInfo.whitelabel &&
                    +businessInfo.whitelabel?.handle_form === 1 && (
                      <Menu.Item style={submenuStyle} key="rsbbui1">
                        <Link style={submenuColor} to="/dashboard/form">
                          {translateMsg(
                            this.props.t,
                            "manager",
                            "mapp_ui_forms"
                          )}
                        </Link>
                      </Menu.Item>
                    )}

                  {businessInfo.whitelabel &&
                    +businessInfo.whitelabel.handle_product === 1 && (
                      <Menu.Item style={submenuStyle} key="rs6">
                        <Link style={submenuColor} to="/dashboard/product">
                          {translateMsg(
                            this.props.t,
                            "manager",
                            "mapp_ui_products"
                          )}
                        </Link>
                      </Menu.Item>
                    )}

                  {businessInfo.whitelabel &&
                    +businessInfo.whitelabel.handle_product === 1 && (
                      <Menu.Item style={submenuStyle} key="rs66">
                        <Link
                          style={submenuColor}
                          to="/dashboard/settingProduct"
                        >
                          {translateMsg(
                            this.props.t,
                            "back",
                            "back_ui_product_settings"
                          )}
                        </Link>
                      </Menu.Item>
                    )}

                  <Menu.Item style={{ display: "none" }} key="rs7">
                    <Link style={submenuColor} to="/dashboard/formula">
                      {translateMsg(this.props.t, "back", "back_ui_formula")}
                    </Link>
                  </Menu.Item>

                  {isHandleSettingProduct(
                    businessInfo,
                    settingProduct,
                    "handle_option"
                  ) === true && (
                    <Menu.Item style={submenuStyle} key="rs8">
                      <Link style={submenuColor} to="/dashboard/options">
                        {translateMsg(
                          this.props.t,
                          "webapp",
                          "pwa_ui__options"
                        )}
                      </Link>
                    </Menu.Item>
                  )}

                  <Menu.Item style={submenuStyle} key="rs9">
                    <Link style={submenuColor} to="/dashboard/menu">
                      {getMenuItemName(businessInfo, this.props.t)}
                    </Link>
                  </Menu.Item>

                  {isHandleSettingProduct(
                    businessInfo,
                    settingProduct,
                    "handle_discount"
                  ) === true &&
                    handleProductDiscount &&
                    +handleProductDiscount.value === 1 && (
                      <Menu.Item style={submenuStyle} key="rs998">
                        <Link style={submenuColor} to="/dashboard/discount">
                          {translateMsg(
                            this.props.t,
                            "webapp",
                            "pwa_ui__discount"
                          )}
                        </Link>
                      </Menu.Item>
                    )}

                  {/*
                  <Menu.Item style={submenuStyle} key="rs12">
                    <Link style={submenuColor} to="/dashboard/promocode">
                      {translateMsg(this.props.t, 'back', 'back_ui_promocode')}
                    </Link>
                  </Menu.Item>
 */}
                </SubMenu>

                <SubMenu
                  key="rsub5"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-android-person" />
                      <span className="nav-text">
                        {translateMsg(this.props.t, "back", "back_ui_users")}
                      </span>
                    </span>
                  }
                >
                  <Menu.Item style={submenuStyle} key="rsa2">
                    <Link
                      style={submenuColor}
                      to="/dashboard/serviceAuthentification"
                    >
                      {translateMsg(this.props.t, "back", "back_ui_auth")}
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="user2">
                    <Link style={submenuColor} to="/dashboard/userLogin">
                      {translateMsg(
                        this.props.t,
                        "back",
                        "back_ui_login_setup"
                      )}
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="user1">
                    <Link style={submenuColor} to="/dashboard/userInfo">
                      {translateMsg(
                        this.props.t,
                        "back",
                        "back_ui_usr_fields_setup"
                      )}
                    </Link>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="rsub6"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-android-options" />
                      <span className="nav-text">
                        {translateMsg(this.props.t, "back", "back_ui_apis")}
                      </span>
                    </span>
                  }
                >
                  <Menu.Item style={submenuStyle} key="rsa1">
                    <Link style={submenuColor} to="/dashboard/api/api">
                      {translateMsg(this.props.t, "back", "back_ui_tools")}
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="rsa1333">
                    <Link style={submenuColor} to="/dashboard/api/payment">
                      {translateMsg(this.props.t, "webapp", "pwa_ui__payment")}
                    </Link>
                  </Menu.Item>

                  {+businessInfo.show_business_users === 1 && (
                    <Menu.Item style={submenuStyle} key="rsa1334">
                      <Link style={submenuColor} to="/dashboard/api/users">
                        {translateMsg(this.props.t, "back", "back_ui_users")}
                      </Link>
                    </Menu.Item>
                  )}

                  <Menu.Item style={submenuStyle} key="rsa2">
                    <Link style={submenuColor} to="/dashboard/qrcode">
                      {translateMsg(this.props.t, "back", "back_ui_qrcode")}
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="rsa345344">
                    <Link style={submenuColor} to="/dashboard/printer">
                      {translateMsg(this.props.t, "back", "back_ui_printer")}
                    </Link>
                  </Menu.Item>

                  {hasPermission(user.permissions, "Debugger") === true && (
                    <Menu.Item style={submenuStyle} key="rsa345343">
                      <Link style={submenuColor} to="/dashboard/printing">
                        {translateMsg(this.props.t, "back", "back_ui_printing")}
                      </Link>
                    </Menu.Item>
                  )}

                  {hasPermission(user.permissions, "Debugger") === true && (
                    <Menu.Item style={submenuStyle} key="rsa4">
                      <Link style={submenuColor} to="/dashboard/template">
                        {translateMsg(
                          this.props.t,
                          "back",
                          "back_ui_templates"
                        )}
                      </Link>
                    </Menu.Item>
                  )}

                  <Menu.Item style={submenuStyle} key="rsa5">
                    <Link style={submenuColor} to="/dashboard/cash_register">
                      Incwo
                    </Link>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="rsub11"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-ios-upload-outline" />
                      <span className="nav-text">
                        {translateMsg(this.props.t, "back", "back_ui_export")}
                      </span>
                    </span>
                  }
                >
                  {+businessInfo.handle_order === 1 && (
                    <Menu.Item style={submenuStyle} key="rsa6">
                      <Link style={submenuColor} to="/dashboard/export/order">
                        {translateMsg(
                          this.props.t,
                          "back",
                          "back_ui_export_orders"
                        )}
                      </Link>
                    </Menu.Item>
                  )}

                  {+businessInfo.handle_order === 1 && (
                    <Menu.Item style={submenuStyle} key="rsa67">
                      <Link
                        style={submenuColor}
                        to="/dashboard/export/orderProduct"
                      >
                        {translateMsg(
                          this.props.t,
                          "back",
                          "back_ui_details_product_sell"
                        )}
                      </Link>
                    </Menu.Item>
                  )}

                  {+businessInfo.handle_order === 1 && (
                    <Menu.Item style={submenuStyle} key="rsa67">
                      <Link
                        style={submenuColor}
                        to="/dashboard/export/orderProductSale"
                      >
                        {translateMsg(
                          this.props.t,
                          "back",
                          "back_ui_export_order_products"
                        )}{" "}
                        NEW
                      </Link>
                    </Menu.Item>
                  )}

                  {isHandleSettingProduct(
                    businessInfo,
                    settingProduct,
                    "handle_option"
                  ) === true &&
                    +businessInfo.handle_order === 1 && (
                      <Menu.Item style={submenuStyle} key="rsa6479">
                        <Link
                          style={submenuColor}
                          to="/dashboard/export/detailOption"
                        >
                          {translateMsg(
                            this.props.t,
                            "back",
                            "back_ui_export_detail_option"
                          )}
                        </Link>
                      </Menu.Item>
                    )}

                  <Menu.Item style={submenuStyle} key="rsa7">
                    <Link style={submenuColor} to="/dashboard/export/user">
                      {translateMsg(
                        this.props.t,
                        "back",
                        "back_ui_user_export"
                      )}
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="rsa8">
                    <Link style={submenuColor} to="/dashboard/export/product">
                      {translateMsg(
                        this.props.t,
                        "back",
                        "back_ui_export_products"
                      )}
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="rsa88">
                    <Link
                      style={submenuColor}
                      to="/dashboard/export/ingredient"
                    >
                      {translateMsg(
                        this.props.t,
                        "back",
                        "back_ui_export_ingredients"
                      )}
                    </Link>
                  </Menu.Item>

                  {+businessInfo.inside_order_payment === 1 && (
                    <Menu.Item style={submenuStyle} key="rsa9">
                      <Link style={submenuColor} to="/dashboard/export/tip">
                        {translateMsg(
                          this.props.t,
                          "back",
                          "back_ui_tip_export"
                        )}
                      </Link>
                    </Menu.Item>
                  )}

                  {+businessInfo.handle_order === 1 && (
                    <Menu.Item style={submenuStyle} key="rsa647">
                      <Link
                        style={submenuColor}
                        to="/dashboard/export/orderMonthly"
                      >
                        {translateMsg(
                          this.props.t,
                          "back",
                          "back_ui_monthly_z_export"
                        )}
                      </Link>
                    </Menu.Item>
                  )}

                  {hasPermission(user.permissions, "Debugger") === true && (
                    <Menu.Item style={submenuStyle} key="rsa6471">
                      <Link
                        style={submenuColor}
                        to="/dashboard/export/business"
                      >
                        {translateMsg(
                          this.props.t,
                          "back",
                          "back_ui_export_business"
                        )}
                      </Link>
                    </Menu.Item>
                  )}
                </SubMenu>

                <SubMenu
                  key="rsub7"
                  title={
                    <span className="isoMenuHolder" style={submenuColor}>
                      <i className="ion-eye" />
                      <span className="nav-text">
                        {translateMsg(this.props.t, "back", "back_ui_design")}
                      </span>
                    </span>
                  }
                >
                  <Menu.Item style={submenuStyle} key="layout">
                    <Link style={submenuColor} to="/dashboard/layout">
                      {translateMsg(this.props.t, "back", "back_ui_layout")}
                    </Link>
                  </Menu.Item>

                  {businessInfo.whitelabel &&
                    +businessInfo.whitelabel?.handle_product === 1 && (
                      <Menu.Item style={submenuStyle} key="ezzzz">
                        <Link
                          style={submenuColor}
                          to="/dashboard/designProduct"
                        >
                          {translateMsg(
                            this.props.t,
                            "back",
                            "back_ui_product_page"
                          )}
                        </Link>
                      </Menu.Item>
                    )}

                  <Menu.Item style={submenuStyle} key="rsa2876">
                    <Link style={submenuColor} to="/dashboard/favicon">
                      {translateMsg(this.props.t, "back", "back_ui_favicon")}
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="rsa2">
                    <Link style={submenuColor} to="/dashboard/logo">
                      {translateMsg(
                        this.props.t,
                        "back",
                        "back_ui_header_logo"
                      )}
                    </Link>
                  </Menu.Item>

                  {/*
                  <Menu.Item style={submenuStyle} key="rsa23332">
                    <Link style={submenuColor} to="/dashboard/logodark">
                      {translateMsg(this.props.t, 'back', 'back_dark_logo')}
                    </Link>
                  </Menu.Item>
                  */}

                  <Menu.Item style={submenuStyle} key="rsa2333244">
                    <Link
                      style={submenuColor}
                      to="/dashboard/appbackgroundimage"
                    >
                      {translateMsg(
                        this.props.t,
                        "back",
                        "back_app_background_image"
                      )}
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="rsa4">
                    <Link style={submenuColor} to="/dashboard/logo_print">
                      {translateMsg(
                        this.props.t,
                        "back",
                        "back_ui_printer_logo"
                      )}
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="imglayout">
                    <Link style={submenuColor} to="/dashboard/img_layout">
                      {translateMsg(
                        this.props.t,
                        "back",
                        "back_ui_background_image"
                      )}
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="mediaV2">
                    <Link style={submenuColor} to="/dashboard/mediaV2">
                      {translateMsg(this.props.t, "back", "back_ui_media")}
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="homeblocV2">
                    <Link style={submenuColor} to="/dashboard/homeblocV2">
                      {translateMsg(
                        this.props.t,
                        "back",
                        "back_ui_content_blocks"
                      )}
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="homebuild">
                    <Link style={submenuColor} to="/dashboard/homebuild">
                      {translateMsg(this.props.t, "back", "back_ui_homepage")}
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="page">
                    <Link style={submenuColor} to="/dashboard/page">
                      {translateMsg(
                        this.props.t,
                        "back",
                        "back_ui_static_pages"
                      )}
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="header">
                    <Link style={submenuColor} to="/dashboard/header">
                      {translateMsg(this.props.t, "back", "back_ui_navbar")}
                    </Link>
                  </Menu.Item>

                  <Menu.Item style={submenuStyle} key="header">
                    <Link style={submenuColor} to="/dashboard/homeIcon">
                      {translateMsg(
                        this.props.t,
                        "back",
                        "back_ui_icons_homepage"
                      )}
                    </Link>
                  </Menu.Item>
                </SubMenu>

                {hasPermission(user.permissions, "Debugger") === true && (
                  <SubMenu
                    key="rsub9"
                    title={
                      <span className="isoMenuHolder" style={submenuColor}>
                        <i className="ion-email" />
                        <span className="nav-text">
                          {translateMsg(
                            this.props.t,
                            "back",
                            "back_ui_email_campaigns"
                          )}
                        </span>
                      </span>
                    }
                  >
                    <Menu.Item style={submenuStyle} key="rsca1">
                      <Link style={submenuColor} to="/dashboard/campaign">
                        {translateMsg(
                          this.props.t,
                          "back",
                          "back_ui_email_campaigns"
                        )}
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                )}

                {hasPermission(user.permissions, "Debugger") === true &&
                  businessInfo.whitelabel &&
                  +businessInfo.whitelabel?.handle_tvscreen === 1 && (
                    <SubMenu
                      key="rsub10"
                      title={
                        <span className="isoMenuHolder" style={submenuColor}>
                          <i className="ion-ios-monitor" />
                          <span className="nav-text">
                            {translateMsg(
                              this.props.t,
                              "back",
                              "back_ui_tvapp"
                            )}
                          </span>
                        </span>
                      }
                    >
                      {/*}
                    <Menu.Item style={submenuStyle} key="rstvapp1">
                      <Link style={submenuColor} to="/dashboard/apptv">
                        {translateMsg(this.props.t, "back", "back_ui_tvapp")}
                      </Link>
                    </Menu.Item>
                  */}

                      <Menu.Item style={submenuStyle} key="rstvapp2">
                        <Link style={submenuColor} to="/dashboard/tvscreen">
                          {translateMsg(
                            this.props.t,
                            "back",
                            "back_ui_tv_screen"
                          )}
                        </Link>
                      </Menu.Item>

                      <Menu.Item style={submenuStyle} key="rstvapp4">
                        <Link
                          style={submenuColor}
                          to="/dashboard/tvscreen_program"
                        >
                          {translateMsg(
                            this.props.t,
                            "back",
                            "back_ui_tv_screen_program"
                          )}
                        </Link>
                      </Menu.Item>

                      <Menu.Item style={submenuStyle} key="rstvapp3">
                        <Link
                          style={submenuColor}
                          to="/dashboard/tvscreen_period"
                        >
                          {translateMsg(
                            this.props.t,
                            "back",
                            "back_ui_tv_screen_period"
                          )}
                        </Link>
                      </Menu.Item>
                    </SubMenu>
                  )}
              </Menu>
            )}
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    app: state.App,
    customizedTheme: state.ThemeSwitcher.sidebarTheme,
    height: state.App.height,
    businessInfo: state.BusinessInfo,
    settingProduct: state.SettingProduct,
    user: state.User,
  }),
  { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed }
)(withTranslation()(Sidebar));
